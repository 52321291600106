import { template as template_ebd99fbf29db4490a9ffb320cc6d869e } from "@ember/template-compiler";
const FKText = template_ebd99fbf29db4490a9ffb320cc6d869e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
