import { template as template_b62686fe96964b6fadab13a8b7a2a69d } from "@ember/template-compiler";
const FKLabel = template_b62686fe96964b6fadab13a8b7a2a69d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
