import { template as template_510ca1e4ae8d4f0fa1fb14ec2e0fffbd } from "@ember/template-compiler";
const SidebarSectionMessage = template_510ca1e4ae8d4f0fa1fb14ec2e0fffbd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
